<template>
    <div>
        <st-page :title="title">
        <loader v-if="isLoading"/>
            <template #toolbar>
                <applications-list-toolbar @refreshApplicationsList="refreshApplicationsList"/>
            </template>
            <applications-list-filter
                ref="applicationsListFilter"
                v-if="initLoading"
                >
                <applications-list-table/>
            </applications-list-filter>
        </st-page>
        <select-organisation-modal
            ref="selectOrganisationModal"
            @organisationSelected="organisationSelected"
        ></select-organisation-modal>
    </div>
</template>

<script>
import ApplicationsListFilter from '../components/ApplicationsListFilter';
import ApplicationsListTable from '../components/ApplicationsListTable';
import ApplicationsListToolbar from '../components/ApplicationsListToolbar';
import SelectOrganisationModal from '@/modules/administrator/components/modals/SelectOrganisationModal.vue';
import Roles from '@/security/roles';
import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';
import { mapGetters } from "vuex";

export default {
    name: 'ApplicationsList',
    components: {
        ApplicationsListFilter,
        ApplicationsListTable,
        ApplicationsListToolbar,
        SelectOrganisationModal
    },
    data() {
        return {
            initLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
        }),
        isLoading() {
            return this.loading['applications/get'];
        },
        title(){
            return this.hasPermissionToReadStaff ? this.$t('APPLICATION.LIST.TITLE_STAFF') : this.$t('APPLICATION.LIST.TITLE');
        },
        hasPermissionToReadStaff() {
            return new ApplicationsPermissions(this.currentUser).readStaff;
        },
        isSuperAdmin() {
            return this.currentUser.user_type_name === Roles.values['super_admin']
        }
    },
    mounted() {
        if (this.isSuperAdmin) {
            this.initLoading = false;
            this.$refs.selectOrganisationModal.show();
        } else {
            this.initLoading = true;
        }
    },
    methods: {
        organisationSelected() {
            this.initLoading = true;
            this.$refs.selectOrganisationModal.hide();
        },
        refreshApplicationsList() {
            this.$refs['applicationsListFilter'].refresh();
        }
    }
}
</script>
